import { createApi } from '@reduxjs/toolkit/query/react';
import { authApiWithToken } from "utils/authApi";

// Custom base Query
const customBaseQuery = async (args) => {
    try {
        const { url, method, body } = args;

        let response;

        switch (method) {
            case 'GET':
                response = await authApiWithToken.get(url);
                break;
            case 'POST':
                response = await authApiWithToken.post(url, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                break;
            case 'PATCH':
                response = await authApiWithToken.patch(url, body);
                break;
        }


        return { data: response.data };
    }
    catch (error) {
        return { error };
    }
}

const itemsApi = createApi({
    reducerPath: 'itemsApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Items'],

    endpoints: (builder) => ({
        getItems: builder.query({
            query: ({ current_account_id }) => ({
                url: `returns_audit/wfsitems/?account_id=${current_account_id}`,
                method: 'GET',
            }),

            // Provides cache tags for items
            providesTags: ['Items'],
        }),
        updateItem: builder.mutation({
            query: ({ id, stringifiedPayload }) => ({
                url: `returns_audit/wfsitem/${id}/`,
                method: 'PATCH',
                body: stringifiedPayload
            }),

            // Invalidates the cache for specific items
            invalidatesTags: ['Items'],
        }),
        uploadBulkFile: builder.mutation({
            query: ({
                csvFile,
                account_id,
                email,
            }) => {
    
                let formData = new FormData();
                
                formData.append('file', csvFile);
                formData.append('account_id', account_id);
                formData.append('email', email);    

                return {
                    url: `returns_audit/wfsitem-upload-file/`,
                    method: 'POST',
                    body: formData
                };
            },
        }),
    })
});

export const { useGetItemsQuery, useUpdateItemMutation, useUploadBulkFileMutation } = itemsApi;

export default itemsApi;