import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signUpData: [],
  tempStepper: [],
  accStepper: [],
  signUpCreds: {},
  isStoreCreates: false,
  currentStep: 0,
};

const userDetailsSlice = createSlice({
  name: "userDetailsSlice",
  initialState,
  reducers: {
    resetSignUp: (state) => {
      state.signUpData = initialState.signUpData;
    },

    resetAllStapperData: (state) => {
      state.tempStepper = initialState.tempStepper;
      state.accStepper = initialState.accStepper;
      state.signUpCreds = initialState.signUpCreds;
      state.timeStampForStore = initialState.timeStampForStore;
      state.isStoreCreates = initialState.isStoreCreates;
    },
    setStoreCreation: (state, action) => {
      state.isStoreCreates = action.payload;
    },
    resetStepperData: (state) => {
      state.stepperForm = initialState.stepperForm;
    },
    setTimeStampForStore: (state, action) => {
      state.timeStampForStore = action.payload;
    },
    resetAccStepper: (state) => {
      state.accStepper = initialState.accStepper;
    },
    resetTimeStampForStore: (state) => {
      state.timeStampForStore = initialState.timeStampForStore;
    },
    setUserData: (state, action) => {
      state.signUpCreds = action.payload; // Mutate directly with Redux Toolkit
    },

    setSignUpData: (state, action) => {
      state.signUpData = action.payload; // Direct mutation
    },

    walmartAccStepper: (state, action) => {
      state.accStepper = action.payload;
    },
    setTempStepperForm: (state, action) => {
      state.tempStepper = action.payload; // Direct mutation
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload; // Direct mutation
    },
  },
});

export const {
  setSignUpData,
  walmartAccStepper,
  setTempStepperForm,
  resetSignUp,
  resetStepperData,
  resetAccStepper,
  resetTimeStampForStore,
  setStoreCreation,
  setUserData,
  setTimeStampForStore,
  resetAllStapperData,
  setCurrentStep,
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
