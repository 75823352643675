import React from "react";

const HelpdeskIcon = (props) => {
  const {
    isSignup
  } = props;

  return (
    <div
      style={{
        position: isSignup ? "fixed" : "absolute",
        bottom: isSignup ? "20px" : "0px",
        right: "15px",
        cursor: "pointer",
        zIndex: 1000,
      }}
      onClick={() =>
        window.open("https://support.refundstacker.com/", "_blank")
      }
    >
      <svg
        width="55"
        height="55"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Question Circle / Light / Duotone"
          clipPath="url(#clip0_4447_180)"
        >
          <g id="Shape" filter="url(#filter0_dd_4447_180)">
            <path
              d="M50.4167 27.4999C50.4167 40.1564 40.1566 50.4166 27.5 50.4166C14.8435 50.4166 4.58337 40.1564 4.58337 27.4999C4.58337 14.8434 14.8435 4.58325 27.5 4.58325C40.1566 4.58325 50.4167 14.8434 50.4167 27.4999Z"
              fill="#003399"
            />
          </g>
          <path
            id="Shape_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.5135 21.7726C24.8798 22.8681 23.4779 23.2425 22.3823 22.6088C21.2868 21.975 20.9124 20.5731 21.5462 19.4776C22.7314 17.4286 24.9524 16.0417 27.5 16.0417C31.297 16.0417 34.375 19.1198 34.375 22.9167C34.375 25.4324 32.8318 27.4863 30.9375 28.6459C29.4159 29.5774 30.3646 32.0834 27.494 32.0834C26.2283 32.0834 25.2023 31.0574 25.2023 29.7917C25.2023 29.7113 25.2065 29.6318 25.2146 29.5535C25.2781 28.3415 25.8186 27.3553 26.407 26.6383C27.0284 25.881 27.8454 25.2628 28.4702 24.8914C31.946 22.8251 27.7066 18.5979 25.5135 21.7726ZM27.494 34.3751C26.2283 34.3751 25.2023 35.4011 25.2023 36.6667C25.2023 37.9324 26.2283 38.9584 27.494 38.9584C28.7596 38.9584 29.7857 37.9324 29.7857 36.6667C29.7857 35.4011 28.7596 34.3751 27.494 34.3751Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_4447_180">
            <rect width="55" height="55" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HelpdeskIcon;