import {
  Card,
  CardContent,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFieldComponent from "../../TextFieldComponent";
import copyImage from "../../../assets/images/copy.png";
import { InfoToast } from "../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";

import {
  resetAccStepper,
  setStoreCreation,
} from "../../../redux/slices/auth/signupSlice";

import {
  loginUser,
  storeUserDetail,
} from "../../../redux/thunk/auth/authThunk";

import { verifyAccess, reset } from "../../../redux/slices/auth/verificationSlice";

import { useNavigate } from "react-router-dom";

const Step2 = (props) => {
  const { buttonref, setStepperData, stepperData } = props;

  const { accStepper, signUpCreds, timeStampForStore } = useSelector(
    (state) => state.root.signup
  );

  const [fillData, setFillData] = React.useState({
    first_name: "Refund",
    last_name: "Stacker",
    email: accStepper?.data?.walmart_login_username,
  });

  const { status, isVerified, error } = useSelector(
    (state) => state.root.verification
  );

  const [timeLeft, setTimeLeft] = React.useState(30);
  const [isVerifying, setIsVerifying] = React.useState(false);

  //const [verficationResult, setVerificationResult] = React.useState(null);

  useEffect(() => {
    dispatch(reset())
    if (accStepper?.walmart_login_username) {
      setFillData({
        ...fillData,
        email: accStepper?.walmart_login_username,
      });
      setStepperData({
        ...stepperData,
        id: accStepper?.id,
        name: accStepper?.name,
        email: accStepper?.email,
      });
    }
  }, [accStepper]);

  useEffect(() => {
    let interval;
    let apiInterval;

    if (isVerifying && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            setIsVerifying(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      // API call every 5 seconds'
      apiInterval = setInterval(() => {
        // API call to verify access
        dispatch(verifyAccess({ to_email_formatted: fillData?.email }));

        if (status === "success") {
          setIsVerifying(false);
          clearInterval(apiInterval);
          clearInterval(interval);
          setTimeout(()=>{
            buttonref.current.click();
          }, 2000)
        }
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (apiInterval) clearInterval(apiInterval);
    };
  }, [isVerifying, status]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { accStepper } = useSelector((state) => state.root.signup);
  // const { signUpCreds } = useSelector((state) => state.root.signup);

  useEffect(() => {
    if (timeStampForStore) {
      //  buttonref.current.click();
    } else if (accStepper?.success) {
      /*
      setFillData({
        ...fillData,
        email: accStepper?.data?.walmart_login_username,
      });
      */
      // buttonref.current.click();
    }
  }, [accStepper, timeStampForStore]);

  const handleSubmit = (e) => {
    // dispatch(resetAccStepper());
    e.preventDefault();

    // dispatch(setStoreCreation(true));

    let req = {
      apiData: signUpCreds,
      navigate: navigate,
    };

    // dispatch(storeUserDetail(req));

    // buttonref.current.click();
    if (!isVerified) {
      setTimeLeft(30);
      setIsVerifying(true);
    }

    if (isVerified === true) {
      buttonref.current.click();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      InfoToast("Copied to clipboard!");
    });
  };

  return (
    <>
      <Typography
        variant="headingXS"
        align="center"
        component="h3"
        sx={{ letterSpacing: "normal" }}
      >
        Grant Access
      </Typography>
      <Typography variant="bodyXS" align="center" component="p" sx={{ mb: 3 }}>
        Refund Stacker needs this permission to serve your business
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="bodyL"
                component="h5"
                sx={{
                  fontWeight: 600,
                  mb: 3,
                }}
              >
                Create Seller Center Account
              </Typography>
              <Typography variant="bodyS" component="p">
                In order for your account manager to open cases on your behalf,
                they will need a Seller Center account.
              </Typography>
              <div className="apiPointList">
                <div className="thumb">1.</div>
                <div className="content">
                  <Typography>
                    Go to{" "}
                    <a
                      className="text-info"
                      style={{ textDecoration: "none" }}
                      href="https://seller.walmart.com/admin/user-management"
                      target="blank"
                    >
                      https://seller.walmart.com/admin/user-management
                    </a>{" "}
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">2.</div>
                <div className="content">
                  <Typography>
                    Click <span style={{ fontWeight: 600 }}>Add New User</span>{" "}
                    and enter the email provided here.
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">3.</div>
                <div className="content">
                  <Typography>
                    Enter the first and last name provided, and select{" "}
                    <span style={{ fontWeight: 600 }}> Read and Write</span> for
                    the role.
                  </Typography>
                </div>
              </div>
              <Typography variant="bodyS" component="p" sx={{ mt: 7, mb: 5 }}>
                Once completed, click{" "}
                <span style={{ fontWeight: 600 }}>Next</span> to start the sync
                and notify our team to start working our magic.
              </Typography>
              <Typography>
                <a
                  target="blank"
                  href="https://drive.google.com/file/d/19BkDn-GAic6n4tID6qWWOvOVHt2yKaEK/view"
                  className="text-info"
                  style={{
                    textDecoration: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  For a more detailed set of instructions, click here.
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card
                style={{
                  boxShadow:
                    "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="bodyL"
                    component="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 3,
                    }}
                  >
                    Grant Access
                  </Typography>
                  <TextFieldComponent
                    id="first_name"
                    name="first_name"
                    label="First name"
                    value={fillData?.first_name}
                    disabled
                    // onChange={handleInputChange}
                    // error={errors?.contact_name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              copyToClipboard(fillData?.first_name)
                            }
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="last_name"
                    name="last_name"
                    label="Last name"
                    type="email"
                    value={fillData?.last_name}
                    // onChange={handleInputChange}
                    // error={errors?.email}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => copyToClipboard(fillData?.last_name)}
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="email"
                    name="email"
                    label="Email address"
                    type="email"
                    value={fillData?.email}
                    disabled
                    // onChange={handleInputChange}
                    // error={errors?.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => copyToClipboard(fillData?.email)}
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {isVerifying ? (
                    <Button variant="contained" fullWidth disabled>
                      <svg
                        className="step2-spinner"
                        width="23"
                        height="22"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="16.5"
                          cy="16"
                          r="14.5"
                          stroke="#F9F9F9"
                          stroke-width="3"
                        />
                        <path
                          d="M26.0406 26.786C26.626 27.4479 26.5682 28.4664 25.8512 28.9829C23.8538 30.4216 21.545 31.3843 19.1021 31.787C16.1312 32.2767 13.0824 31.9181 10.3062 30.7525C7.53003 29.587 5.13905 27.6617 3.40813 25.198C1.67721 22.7343 0.676595 19.8322 0.521296 16.8252C0.365998 13.8183 1.06232 10.8286 2.53023 8.19965C3.99813 5.57075 6.17805 3.4094 8.8194 1.96403C11.4608 0.51866 14.4563 -0.152069 17.4619 0.0289378C19.9333 0.177781 22.329 0.897593 24.464 2.12284C25.2304 2.56268 25.3928 3.56987 24.8786 4.28854C24.3645 5.00721 23.3688 5.16302 22.5917 4.74248C20.9553 3.857 19.1395 3.33577 17.2695 3.22315C14.8651 3.07834 12.4686 3.61493 10.3555 4.77123C8.24244 5.92752 6.49851 7.6566 5.32418 9.75972C4.14986 11.8628 3.5928 14.2546 3.71704 16.6602C3.84128 19.0658 4.64177 21.3874 6.02651 23.3584C7.41124 25.3293 9.32402 26.8696 11.545 27.802C13.7659 28.7345 16.2049 29.0213 18.5816 28.6296C20.4301 28.3249 22.1825 27.6194 23.719 26.5701C24.4487 26.0717 25.4551 26.1241 26.0406 26.786Z"
                          fill="#474747"
                        />
                      </svg>
                      Verifying ({timeLeft}s)
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit}
                        sx={{
                          bgcolor:
                            isVerified === true ? "#D4F7DF" : "primary.main", // Use custom color for verified, fallback to primary
                          color: isVerified === true ? "#1CAD63" : "#fff", // Adjust text color for contrast
                          "&:hover": {
                            bgcolor:
                              isVerified === true ? "#BEECCC" : "primary.dark", // Lighter shade on hover for verified
                          },
                        }}
                      >
                        {isVerified === true ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M20.738 5.35233C21.0957 4.93153 21.7268 4.88036 22.1476 5.23804C22.5684 5.59573 22.6196 6.22682 22.2619 6.64763L13.7619 16.6476C13.3986 17.075 12.7549 17.1201 12.3356 16.7474L7.83562 12.7474C7.42283 12.3805 7.38565 11.7484 7.75257 11.3356C8.11949 10.9228 8.75156 10.8857 9.16434 11.2526L12.9002 14.5733L20.738 5.35233Z"
                              fill="#50CD89"
                            />
                            <path
                              d="M15.738 6.35233C16.0957 5.93153 16.7268 5.88036 17.1476 6.23804C17.5684 6.59573 17.6196 7.22682 17.2619 7.64763L8.76192 17.6476C8.39862 18.075 7.75488 18.1201 7.33562 17.7474L2.83562 13.7474C2.42283 13.3805 2.38565 12.7484 2.75257 12.3356C3.11949 11.9228 3.75156 11.8857 4.16434 12.2526L7.90019 15.5733L15.738 6.35233Z"
                              fill="#1CAD63"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                        {isVerified === true
                          ? " Access Verified"
                          : "Verify Access"}
                      </Button>

                      {isVerified === false && status === "failed" && (
                        <Typography
                          color="error"
                          variant="body2"
                          component="p"
                          sx={{
                            lineHeight: "16px",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            textAlign: "center",
                            marginTop: 2,
                            fontSize: "14px", // Explicitly set smaller font size if needed
                            color: "#9D1444",
                            fontWeight: "700",
                            "& .emphasis": {
                              fontWeight: "900", // Make it bolder
                              color: "#5A0E2C", // Darker shade for emphasis
                            },
                          }}
                        >
                          Invitation email has not been received. Please verify
                          the user has been created with the correct email and
                          click <span className="emphasis">Verify Access</span>{" "}
                          again. If the issue persists, please contact support.
                        </Typography>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Step2;