import React, { useEffect, useRef, useState } from "react";
import Step1 from "../../../components/StepperComponent/Steps/Step1";
import Step2 from "../../../components/StepperComponent/Steps/Step2";
import Step3 from "../../../components/StepperComponent/Steps/Step3";
import StepperHandler from "../../../components/StepperComponent/StepperHandler";
import Step4 from "../../../components/StepperComponent/Steps/Step4";
import { useDispatch } from "react-redux";
import { getTimeStampForStore } from "../../../redux/thunk/auth/authThunk";
import HelpdeskIcon from "../../../components/HelpdeskIcon";

const GetStepper = () => {
  const [stepperData, setStepperData] = useState({
    contact_name: "",
    email: "",
    billing_contact_name: "",
    billing_email: "",
    name: "",
  });
  const [usePrimaryEmail, setUsePrimaryEmail] = useState(false);
  const [useBillingPrimaryEmail, setUseBillingPrimaryEmail] = useState(false);
  const [errors, setErrors] = useState({});
  const buttonref = useRef(null);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getTimeStampForStore());
  }, []);

  /**
   * Returns the content for a specific step in the stepper.
   * 
   * @param {number} stepIndex - The index of the step.
   * @returns {React.ReactNode} The content for the specified step.
   */
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      
      case 0:
        return (
          <Step1
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 1:
        return (
          <Step2  
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
          />
        );
      case 2:
        return (
          <Step3
            buttonref={buttonref}
            stepperData={stepperData}
            setStepperData={setStepperData}
            usePrimaryEmail={usePrimaryEmail}
            useBillingPrimaryEmail={useBillingPrimaryEmail}
            setUsePrimaryEmail={setUsePrimaryEmail}
            setBillingPrimaryEmail={setUseBillingPrimaryEmail}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 3:
        return <Step4 />;
      default:
        return "Unknown stepIndex";
    }
  };
  const steps = ["", "", "", ""];

  return (
    <>
      <div className="wallmartAccount">
        <StepperHandler
          steps={steps}
          getStepContent={getStepContent}
          buttonref={buttonref}
        />
        <HelpdeskIcon isSignup={true} />
      </div>
    </>
  );
};

export default GetStepper;
