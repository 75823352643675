import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import TextFieldComponent from "../../TextFieldComponent";
import { validateStepThree } from "../../../utils/Validation";
import { useDispatch, useSelector } from "react-redux";
import { setTempStepperForm } from "../../../redux/slices/auth/signupSlice";
import { updateStoreData } from "../../../redux/thunk/storedetails/stroredetailsThunk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Step3 = (props) => {
  const {
    buttonref,
    stepperData,
    setStepperData,
    usePrimaryEmail,
    setUsePrimaryEmail,
    errors,
    setErrors,
    useBillingPrimaryEmail,
    setBillingPrimaryEmail,
    authType,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const accStepper = useSelector((state) => state.root.signup);
  const { accStepper, timeStampForStore } = useSelector(
    (state) => state.root.signup
  );
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "usePrimaryEmail") {
        setUsePrimaryEmail(checked);
        if (checked) {
          setStepperData((prev) => ({
            ...prev,
            billing_contact_name: prev.contact_name,
            billing_email: prev.email,
          }));
        }
      } else if (name === "useBillingPrimaryEmail") {
        setBillingPrimaryEmail(checked);
        if (checked) {
          setStepperData((prev) => ({
            ...prev,
            pod_contact_name: prev.billing_contact_name, // Assuming you want to update pod_email based on billing_email
            pod_email: prev.billing_email, // Assuming you want to update pod_email based on billing_email
          }));
        }
      }
    } else {
      // Clear the error for the current field
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));

      // Update the stepOne state
      setStepperData((prev) => ({
        ...prev,
        [name]: value,
      }));

      // If 'usePrimaryEmail' is checked and the changed field is 'email'
      if (usePrimaryEmail && name === "email") {
        setStepperData((prev) => ({
          ...prev,
          billing_email: value,
        }));
      }

      // If 'useBillingPrimaryEmail' is checked and the changed field is 'billing_email'
      if (useBillingPrimaryEmail && name === "billing_email") {
        setStepperData((prev) => ({
          ...prev,
          pod_email: value, // Assuming you want to update pod_email based on billing_email
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateStepThree(stepperData);
    if (isValid) {
      let formdata = {
        ...stepperData,
        signup: true
      }
      if(!formdata.name || formdata.name == ''){
        formdata.name = accStepper?.name
      }
      dispatch(
        updateStoreData({
          formdata,
          id: accStepper?.id,
        })
      );
      buttonref.current.click();
      if (authType == "3") {
        navigate("/store-details");
      }
    }
    if (!isValid) {
      setErrors(errors);
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <div>
          <Typography
            variant="headingXS"
            align="center"
            component="h3"
            sx={{ letterSpacing: "normal" }}
          >
            Add Contact Information
          </Typography>
          <Typography
            variant="bodyXS"
            align="center"
            component="p"
            style={{ letterSpacing: "normal" }}
          >
            This helps secure and verify your account
          </Typography>
          <div style={{ marginTop: "40px" }}>
            <TextFieldComponent
              id="contact_name"
              name="contact_name"
              label="Primary contact name"
              value={stepperData?.contact_name}
              infoText="Main point of contact."
              onChange={handleInputChange}
              error={errors?.contact_name}
            />
            <TextFieldComponent
              id="email"
              name="email"
              label="Primary email"
              type="email"
              value={stepperData?.email}
              onChange={handleInputChange}
              error={errors?.email}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={usePrimaryEmail}
                  onChange={handleInputChange}
                  color="primary"
                  name="usePrimaryEmail"
                />
              }
              label={
                <span className="secondary-text-color font-tiny">
                  Use same email as my primary email
                </span>
              }
            />
            <TextFieldComponent
              id="billing_contact_name"
              name="billing_contact_name"
              label="Billing contact name"
              value={stepperData?.billing_contact_name}
              infoText="Point of contact for all billing related items."
              onChange={handleInputChange}
              error={errors?.billing_contact_name}
            />
            <TextFieldComponent
              id="billing_email"
              name="billing_email"
              label="Billing email"
              type="email"
              value={stepperData?.billing_email}
              onChange={handleInputChange}
              error={errors?.billing_email}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useBillingPrimaryEmail}
                  onChange={handleInputChange}
                  color="primary"
                  name="useBillingPrimaryEmail"
                />
              }
              label="Use same email as my primary email"
            />
            <TextFieldComponent
              id="pod_contact_name"
              name="pod_contact_name"
              label="POD Contact Name"
              infoText="Point of contact for requesting case related questions and documents such as Proof of Delivery (POD)."
              value={stepperData?.pod_contact_name}
              onChange={handleInputChange}
              error={errors?.pod_contact_name}
            />
            <TextFieldComponent
              id="pod_email"
              name="pod_email"
              label="POD Email"
              value={stepperData?.pod_email}
              onChange={handleInputChange}
              error={errors?.pod_email}
            />
            <Button
              sx={{
                mt: 2,
              }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Next
            </Button>
          </div>
        </div>

      </Grid>
    </Grid>
  );
};

export default Step3;