import {
    Modal,
    Box,
    Typography,
    Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const ConfirmationModal = ({
    open,
    handleClose, 
    onConfirm,
    title,
    message,

    // New Props for button customization

    cancelButtonText = "Cancel",
    confirmButtonText = "Disconnect",

    cancelButtonProps = {},
    confirmButtonProps = {},
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="modal-core"
            >
                <Box
                    className="modal-header"
                >
                    <Typography id="modal-modal-title" variant="h6" fontWeight="700">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Content */}
                <Box sx={{ 
                    padding: "32px 20px",
                    minHeight: "175px",
                }}>
                    <Typography 
                        id="modal-modal-description"
                        sx={{
                            fontSize: "14px",
                        }}
                    >
                        {message}
                    </Typography>
                </Box>
                <Box className="modal-footer">
                    <Button 
                        onClick={handleClose} 
                        sx={{ mr: 1 }}
                        {...cancelButtonProps}
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        variant="contained"
                        {...confirmButtonProps}
                    >
                        {confirmButtonText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;