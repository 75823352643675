import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCommanHeader } from "../../../redux/slices/dashboard/dashboardSlice";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { updateAccountData } from "../../../redux/thunk/profileandaccount/profileandaccountThunk";
import withHighlight from "../../../components/GlobalSearch/withHighlight";
import HeaderNevigation from "../../../components/HeaderNevigation/HeaderNevigation";

function ProfileAndAcount({ highlightSearch }) {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    company_name: Yup.string().required("Company name is required"),
    phone_num: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });
  const headerData = [
    {
      linkName: "Setting",
      link: "/profileandacount",
      active: false,
    },
    {
      linkName: "Profile and Account",
      link: "/profileandacount",
      active: true,
    },
  ];
  const validationSchemaForMail = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState({
    id: "",
    first_name: "",
    last_name: "",
    company_name: "",
    phone_num: "",
  });

  const [emailInitialValue, setEmailInitialValue] = useState({
    email: "",
  });

  const [id, setId] = useState();
  const { user_detail } = useSelector((state) => state.root?.login?.user);

  useEffect(() => {
    dispatch(setCommanHeader("Settings"));
    const details = {
      id: user_detail?.id,
      first_name: user_detail?.first_name,
      last_name: user_detail?.last_name,
      company_name: user_detail?.company_name,
      phone_num: user_detail?.phone_number,
    };
    setInitialValue(details);

    const emailDetails = {
      email: user_detail?.email,
    };
    setEmailInitialValue(emailDetails);
    setId(user_detail?.id);
    return () => {
      dispatch(setCommanHeader(""));
    };
  }, [user_detail]);

  return (
    <>
      <HeaderNevigation headerData={headerData} />

      <Card>
        <CardContent>
          <Typography
            variant="headingXXXS"
            component="h4"
            sx={{ fontSize: "16px", letterSpacing: "auto" }}
          >
            {highlightSearch("User Information")}
          </Typography>
          <Divider sx={{ pt: 2, mb: 3 }} />
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              try {
                const formData = {
                  first_name: values?.first_name,
                  last_name: values?.last_name,
                  company_name: values?.company_name,
                  phone_number: values?.phone_num,
                };

                dispatch(updateAccountData({ formData, id }));
              } catch (error) {
                console.log(error);
              }
            }}
            enableReinitialize
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Full Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextFieldComponent
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.first_name && errors.first_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldComponent
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.last_name && errors.last_name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Company Name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="company_name"
                      name="company_name"
                      placeholder="Company Name"
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.company_name && errors.company_name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Phone Number")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="phone_num"
                      name="phone_num"
                      placeholder="Phone Number"
                      value={values.phone_num}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone_num && errors.phone_num}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ pt: 1 }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ textAlign: "right", pt: 2 }}
                  >
                    <Button
                      onClick={() => resetForm()}
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Discard
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography
            variant="headingXXXS"
            component="h4"
            sx={{ fontSize: "16px", letterSpacing: "auto" }}
          >
            {highlightSearch("Account Details")}
          </Typography>
          <Divider sx={{ pt: 2, mb: 3 }} />
          <Formik
            initialValues={emailInitialValue}
            validationSchema={validationSchemaForMail}
            enableReinitialize
            onSubmit={(values) => {
              try {
                const formData = {
                  email: values.email,
                };

                dispatch(updateAccountData({ formData, id }));
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="bodyS" component="label">
                      {highlightSearch("Email")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextFieldComponent
                      id="email"
                      name="email"
                      placeholder="abc@xyz.com"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ pt: 1 }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ textAlign: "right", pt: 2 }}
                  >
                    <Button
                      onClick={() => resetForm()}
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Discard
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
}

export default withHighlight(ProfileAndAcount);
